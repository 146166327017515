
// First account
//export const AERIS_CLIENT_ID = 'O8Q8wDiAL8Uj4WHK5UF0x';
//export const AERIS_CLIENT_PASSWORD = 'ew8HnTMI4qaQELVxBjbaXUpl7LwE5bHabWyB03L7';

// Second
//export const AERIS_CLIENT_ID = 'vdsf7nc9vlMCJBOX2GZ54';
//export const AERIS_CLIENT_PASSWORD = 'LnUi9VbBQrHU76BowQHdYkP417hlqqHyR0wBa1V6';

// Third
//export const AERIS_CLIENT_ID = 'yP4omYWprIt6vq5ARL4qI',
//export const AERIS_CLIENT_PASSWORD = 'JWaAfcwQbRRHb325u4wKrieZFaLyS7DjvLarNz6h',

// Jess' account
//export const AERIS_CLIENT_ID = '3Mgb9pMcYdnhhFruoDR8o';
//export const AERIS_CLIENT_PASSWORD = '4pLT3GsGyzdPo7s9sQ4PVjYSYeZiUtxhMpWZovts';

// Client's account
// to not load Aeris map data (e.g. to save network bandwidth, set REACT_APP_NO_AERIS_MAP=true in either .env or .env.local in the root directory
export const AERIS_CLIENT_ID = process.env.REACT_APP_NO_AERIS_MAP_CALLS === 'true' ? '' : 'icgy89ko1slCRZaQdS4yW';
export const AERIS_CLIENT_PASSWORD = process.env.REACT_APP_NO_AERIS_MAP_CALLS === 'true' ? '' : 'ltVnjmfYHFXGq1drbweIQhJ3aWf1INOW11nVFOAI';

// Others
export const frameTimes = Array.apply(null, Array(25))
.map(Number.prototype.valueOf,0)
.map((el,i) => {
    return {
        id : i,
        time : -120 + (i * 5)
    }
});

export const forecastRadarFrameTimes = Array.apply(null, Array(25))
.map(Number.prototype.valueOf,0)
.map((el,i) => {
    return {
        id : i,
        time : (i * 15)
    }
});

export const ANIMATION_PAUSE_LIMIT = 1; //minutes

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Tooltip, OverlayTrigger, } from 'react-bootstrap';
import { Formik, ErrorMessage } from "formik";
import { email } from "utils/formValidation";
import MultiSearchBoxSelector from 'common/components/MultiSearchBoxSelector';
import { WwaCheckbox, WwaRadio } from "common/components/WwaInputElements";
import { friendlyReportType, getUserIcons, isThirdPartyUser, reportTypeToolTip } from 'common/helpers/helpers';
import ResultPool from 'common/components/ResultWithDelete/ResultPool';
import 'Admin/common/css/TableHead.css';
import './ReportsExecute.css';
import { displayFormat } from "Admin/ReportSettings/ReportSettings";
import 'Admin/ReportSettings/ReportSettings.css';

const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

class ReportsExecute extends React.Component {
  initialState = {
    selectedCompanyId: '',
    companySearchBoxField: '',
    checkedReportTypes: [],
    allReportTypesSelected: false,
    selectedUserTypes: '',
    userSearchBoxField: '',
    chosenUserIds: [],
    changed: false
  }

  constructor(props){
    super(props);

    if(props.companyId)
      props.setSelectedCompany(props.companyId);

    this.state = {
      ...this.initialState,
      companyId: props.companyId,
      selectedCompanyId: props.companyId
    }
  }

  componentDidMount() {
    if(this.props.companyId)
      this.props.loadCompanyPersons(this.props.companyId)
  }

  handleSelectCompany = id => {
    this.setState({
      ...this.initialState,
      selectedCompanyId: id,
      changed: true
    },
    () => this.props.setSelectedCompany(id)
  )
    this.props.loadCompanyPersons(id)
  }

  handleCompanySearchInputChange = v => this.setState({
    companySearchBoxField: v,
    changed: true
  })

  handleSelectAllCheckboxChange = e => e.target.checked
    ? this.setState({
      checkedReportTypes: [...this.props.reportTypes], allReportTypesSelected: true,
      changed: true
    })
    : this.setState({
      checkedReportTypes: [], allReportTypesSelected: false,
      changed: true
    })

  handleReportTypeCheckbox = (e, rt) => {
    const checked = e.target.checked
    this.setState(state => {
      if (checked && !state.checkedReportTypes.some(rt1 => rt1 === rt))
        return {
          checkedReportTypes: [...state.checkedReportTypes, rt],
          allReportTypesSelected: false,
          changed: true
        }
      else if (!checked)
        return {
          checkedReportTypes: state.checkedReportTypes.filter(rt1 => rt1 !== rt),
          allReportTypesSelected: false,
          changed: true
        }
    })
  }

  handleChangeUserTypes = (e) => {
    const v = e.target.value
    this.setState({
      selectedUserTypes: v,
      changed: true
    })
  }

  handleSelectUser = id => !this.state.chosenUserIds.find(id1 => id1 === id) && this.setState(state => ({
      chosenUserIds: [...state.chosenUserIds, id],
      change: true
    }
  ))

  handleUserSearchInputChange = v => {
    this.setState({
    userSearchBoxField: v,
    changed: true
  })
  }

  removeChosenUser = id => this.setState(state => ({
    chosenUserIds: state.chosenUserIds.filter(u => u !== id),
    changed: true
  }))

  handleButtonClick = () => {
    this.setState({changed: false},
      () => this.props.executeReport (
        this.state.selectedCompanyId,
        this.state.checkedReportTypes,
        this.state.selectedUserTypes,
        this.state.selectedUserTypes === 'selected'
          ? this.state.chosenUserIds.filter(id => !isThirdPartyUser(this.props.selectedCompanyPersonsById[id]))
          : [],
        this.state.selectedUserTypes === 'selected'
          ? this.state.chosenUserIds.filter(id => isThirdPartyUser(this.props.selectedCompanyPersonsById[id]))
          : []
      )
    )
  }

  handleResendButtonClick = (values) => {
    this.props.executeResendReport(
      values.operationHour,
      values.overrideMailDrop
    );
  };

  handleAllButtonClick = () => {
    this.props.executeReport();
  }

  render() {
    const {isFetching, error, success, companies, companiesDS, companyPersons, usersDS, thirdPartyUsersDS} = this.props;

    return (
      <div className='ReportsExecute__outer'>
        <div className='ReportsExecute__contentbox'>
          <div className='TableHead ReportsExecute__header'>
            Force execute reports operations
          </div>

          <div className='ReportsExecute__helperText'>
            <i className="fa fa-exclamation-triangle"></i>{' '}
            Use this form to create reports for a specific company, selected report types and selected users.<p/>
            Will unconditionally create these reports irrespective of the company's report times.
          </div>

          <div className='ReportsExecute__controlbox'>

            {error && <Alert variant='warning'>
                {error}
              </Alert>
            }
            {success === true && !this.state.changed && <Alert variant='success'>
                Report creation was started successfully!
              </Alert>
            }

            {/* 
              Execute by single company
            */}
            {!this.props.showRestrictedForm &&
              <MultiSearchBoxSelector
                closeOnSelect
                minSearchLen={0}
                placeHolder='Search company by name'
                dataSources={[companiesDS]}
                fields={['name']}
                handleSelect={this.handleSelectCompany}
                onChange={this.handleCompanySearchInputChange}
                value={this.state.companySearchBoxField}
              />
            }

            {this.state.selectedCompanyId &&
              <div>
                {!this.props.showRestrictedForm &&
                  <div className='ReportsExecute__selectedCompany ReportsExecute__header'>
                    Selected company: {Object.keys(companies).length && companies[this.state.selectedCompanyId].name}
                  </div>
                }

                {
                  (!!this.props.usersDS.data && !this.props.usersDS.data.length) &&
                  (!!this.props.thirdPartyUsersDS.data && !this.props.thirdPartyUsersDS.data.length)
                  ? <div className='ReportsExecute__title ReportsExecute__header'>
                      <Alert>This company doesn't seem to have any users.</Alert>
                    </div>
                  : <div>
                    <div className='ReportsExecute__title ReportsExecute__header'>
                      Select reports type
                    </div>
                    <WwaCheckbox id={'all'}
                                 checked={this.state.allReportTypesSelected}
                                 onChange={(e) => this.handleSelectAllCheckboxChange(e)}/>
                    <span className='ReportsExecute__reportTypeName'>Select all</span>

                      {this.props.reportTypes.map(rt => <div key={rt} className='ReportsExecute__reportTypeCheckbox'>
                        <WwaCheckbox id={rt}
                          checked={this.state.checkedReportTypes.some(rt1 => rt1 === rt)}
                          onChange={(e) => this.handleReportTypeCheckbox(e, rt)} />
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{reportTypeToolTip(rt)}</Tooltip>}
                        >
                          <span className='ReportsExecute__reportTypeName'>
                            {friendlyReportType(rt)}{" "}
                            <i className="fa fa-info-circle" aria-hidden="true" />
                          </span>
                        </OverlayTrigger>
                      </div>
                      )}

                    <div className='ReportsExecute__title ReportsExecute__header'>
                      Execute and send respective reports to
                    </div>

                    <div>
                      <WwaRadio id="radio-1" name="radio-1-set" value='all' onChange={this.handleChangeUserTypes}
                                checked={this.state.selectedUserTypes === 'all'}/>
                      <span className='ReportsExecute__reportTypeName'>All users</span>
                    </div>
                    <div>
                      <WwaRadio id="radio-2" name="radio-1-set" value='companyUser' onChange={this.handleChangeUserTypes}
                                checked={this.state.selectedUserTypes === 'companyUser'}/>
                      <span className='ReportsExecute__reportTypeName'>All Company users only</span>
                    </div>
                    <div>
                      <WwaRadio id="radio-3" name="radio-1-set" value='thirdPartyUser'
                                onChange={this.handleChangeUserTypes}
                                checked={this.state.selectedUserTypes === 'thirdPartyUser'}/>
                      <span className='ReportsExecute__reportTypeName'>All Third-party users only</span>
                    </div>
                    <div>
                      <WwaRadio id="radio-4" name="radio-1-set" value='selected' onChange={this.handleChangeUserTypes}
                                checked={this.state.selectedUserTypes === 'selected'}/>
                      <span className='ReportsExecute__reportTypeName'>Selected users only</span>
                    </div>

                    {this.state.selectedUserTypes === 'selected' && (Object.keys(usersDS)?.length || Object.keys(thirdPartyUsersDS)?.length) && <div>
                        <MultiSearchBoxSelector
                          minSearchLen={0}
                          placeHolder='Search users by name'
                          dataSources={[usersDS, thirdPartyUsersDS]}
                          fields={['username', 'name', 'email']}
                          handleSelect={this.handleSelectUser}
                          onChange={this.handleUserSearchInputChange}
                          value={this.state.userSearchBoxField}
                          iconFunc={obj => 
                            getUserIcons(companyPersons[this.state.selectedCompanyId].find(user => user.id === obj))
                          }
                        />
                        <ResultPool
                          resultList={this.state.chosenUserIds.map(uid => companyPersons[this.state.selectedCompanyId].find(user => user.id === uid))}
                          fieldNames={['username', 'name', 'email']}
                          handleDelete={this.removeChosenUser}
                          iconFunc={getUserIcons}
                        />
                      </div>
                    }
                  </div>
                }
              </div>
            }
          </div>

          <div className='ReportsExecute__button'>
            <Button
              variant='primary'
              onClick={this.handleButtonClick}
              disabled={isFetching ||
                !this.state.selectedCompanyId ||
                (!this.state.selectedUserTypes) ||
                (this.state.selectedUserTypes === 'selected' && !this.state.chosenUserIds.length) ||
                (!this.state.checkedReportTypes.length)
              }>
              Execute reports operation
            </Button>
          </div>

          {/* 
            Execute by report hour
          */}
          {!this.props.showRestrictedForm &&
            <>
            <hr style={{borderTop: "2px solid #ddd"}}/>
            <div className='ReportsExecute__helperText'>
              <i className="fa fa-exclamation-triangle"></i>{' '}
              Use this button to re-create and resend reports for all companies according to the companies' Report Timings and subscriptions. <p/>
              One use case is if hourly report operations failed and should be rerun. <p/>
              The dropdown selects for which hour the reports should be resent.<p/> 
              If an optional email address is given, all reports are sent to that
                address and not to the subscribed users.
            </div>

            <Formik
              validate={values => {
                const errors = {};
                if(email(values.overrideMailDrop)) {
                  errors.overrideMailDrop = 'Not a valid email address.'
                } 
                return errors;
              }}

              onSubmit={this.handleResendButtonClick}

              initialValues={{ operationHour: 1, overrideMailDrop: '' }}
            >
              {({ submitForm, handleSubmit, values, errors, touched, handleChange, handleBlur, resetForm, dirty }) => (
                <form onSubmit={handleSubmit}>
                  <div className='ReportsExecute__resend'>
                    <div className='ReportsExecute__button'>
                      <Button
                        variant='primary'
                        type="submit"
                        disabled={isFetching}>
                        Execute reports operation for all Companies by report hour
                      </Button>
                    </div>
                    <select
                      name="operationHour"
                      className='wwa__select ReportsExecute__selectHour'
                      style={{height: '34px', marginTop:"20px", marginBottom:"0"}}
                      onChange={handleChange}
                      value={values.operationHour}
                    >
                      {hours
                        .map(h =>
                          <option key={h}
                                  value={h}
                          >
                            {displayFormat(h)}
                          </option>
                        )}
                    </select>
                    <input 
                      name="overrideMailDrop"
                      className="ReportsExecute__overrideMailDrop"
                      type="email" 
                      value={values.overrideMailDrop}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Override email"
                    />
                  </div>
                  <div className='ReportExecute__errors'>
                    <ErrorMessage name="overrideMailDrop" />
                  </div>
                </form>
              )}
            </Formik>
            </>
          }

          {/* 
            Execute by for all companies and sites 
          */}
          {!this.props.showRestrictedForm &&
            <>
            <hr style={{borderTop: "2px solid #ddd"}}/>
            <div className='ReportsExecute__helperText'>
              <i className="fa fa-exclamation-triangle"></i>{' '}
              Use this button to create reports for all report types, all users and sites of all companies irrespective of the companies' report times. <p/>
              N.B. if a hourly run already created reports for some companies/sites, these will be created and sent again unconditionally.
            </div>
            <div className='ReportsExecute__button'>
              <Button
                variant='primary'
                onClick={this.handleAllButtonClick}
                disabled={isFetching}>
                Execute reports operation for all Companies and Sites
              </Button>
            </div>
            </>
          }
        </div>
      </div>
    )
  }
}

ReportsExecute.propTypes = {
  selectedCompanyId: PropTypes.string,
  companies: PropTypes.object.isRequired,
  companiesDS: PropTypes.object.isRequired,
  companyPersons: PropTypes.object,
  usersDS: PropTypes.object.isRequired,
  thirdPartyUsersDS: PropTypes.object.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  executeReport: PropTypes.func.isRequired,
  setSelectedCompany: PropTypes.func.isRequired,
}

export default ReportsExecute

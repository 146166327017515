export const SITEPAGE_SELECT_SITE = "SITEPAGE_SELECT_SITE";
export const SITEPAGE_SET_SORT_COLUMN = "SITEPAGE_SET_SORT_COLUMN";
export const SITEPAGE_SET_PAGESIZE = "SITEPAGE_SET_PAGESIZE";
export const SITEPAGE_SET_RUBRIC_FILTER = "SITEPAGE_SET_RUBRIC_FILTER";
export const SITEPAGE_SET_BULK_TOGGLE = "SITEPAGE_SET_BULK_TOGGLE";
export const SITEPAGE_SET_PULSING_RUBRIC = "SITEPAGE_SET_PULSING_RUBRIC";
export const SITEPAGE_SET_SEARCH_FIELD = "SITEPAGE_SET_SEARCH_FIELD";
export const SITEPAGE_SET_IS_WINTER_SELECTED = "SITEPAGE_SET_IS_WINTER_SELECTED";
export const SITEPAGE_SET_IS_NON_WINTER_SELECTED = "SITEPAGE_SET_IS_NON_WINTER_SELECTED";
export const SITEPAGE_SET_SELECTED_SITES = "SITEPAGE_SET_SELECTED_SITES";
export const SITEPAGE_SELECT_FILTERED_SITE = "SITEPAGE_SELECT_FILTERED_SITE";
export const SITEPAGE_SELECT_ALL_FILTERED_SITES = "SITEPAGE_SELECT_ALL_FILTERED_SITES";
export const SITEPAGE_TOGGLE_SELECT_ALL_SITES = "SITEPAGE_TOGGLE_SELECT_ALL_SITES";
export const SITE_MANAGE_RESET = "SITE_MANAGE_RESET";

export const setSelectedSite = id => ({
  type: SITEPAGE_SELECT_SITE,
  id
});

export const setSelectedFilteredSite = id => ({
  type: SITEPAGE_SELECT_FILTERED_SITE,
  id
});


export const reset = () => ({
  type: SITE_MANAGE_RESET
});

export const setSortColumn = (sortColumn, sortDirection) => ({
  type: SITEPAGE_SET_SORT_COLUMN,
  sortColumn,
  sortDirection
});

export const setPageSize = pageSize => ({
  type: SITEPAGE_SET_PAGESIZE,
  pageSize
});

export const setRubricFilter = rubric => ({
  type: SITEPAGE_SET_RUBRIC_FILTER,
  rubric
});

export const setSearchField = searchField => ({
  type: SITEPAGE_SET_SEARCH_FIELD,
  searchField
});

export const setIsWinterSelected = isWinterSelected => ({
  type: SITEPAGE_SET_IS_WINTER_SELECTED,
  isWinterSelected
});

export const toggleSelectAllSites = (selectedSites) => ({
  type: SITEPAGE_TOGGLE_SELECT_ALL_SITES,
  selectedSites
});

export const setIsNonWinterSelected = isNonWinterSelected => ({
  type: SITEPAGE_SET_IS_NON_WINTER_SELECTED,
  isNonWinterSelected
});

export const setBulkToggle = bulkToggleState => ({
  type: SITEPAGE_SET_BULK_TOGGLE,
  bulkToggleState
});

const initialState = {
  selectedSite: undefined,
  sortColumn: "name",
  sortDirection: 1,
  rubric: "all",
  bulkToggleState: undefined,
  pulsingRubric: undefined,
  isWinterSelected: false,
  isNonWinterSelected: false,
  selectedSites: []
};

const sitesManage = (state = initialState, action) => {
  switch (action.type) {
    case SITEPAGE_SELECT_SITE:
      return {
        ...state,
        selectedSite: action.id,
        pulsingRubric: undefined
      };

    case SITEPAGE_SET_SORT_COLUMN:
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortDirection: action.sortDirection
      };

    case SITEPAGE_SET_PAGESIZE:
      return {
        ...state,
        selectedSite: undefined,
        pageSize: Number(action.pageSize)
      };

    case SITEPAGE_SET_RUBRIC_FILTER:
      return {
        ...state,
        rubric: action.rubric
      };

    case SITEPAGE_SET_PULSING_RUBRIC:
      return {
        ...state,
        pulsingRubric: action.rubric
      };

    case SITEPAGE_SET_BULK_TOGGLE:
      return {
        ...state,
        bulkToggleState: action.bulkToggleState
      };

    case SITEPAGE_SET_SEARCH_FIELD:
      return {
        ...state,
        searchField: action.searchField
      };

    case SITEPAGE_SET_IS_WINTER_SELECTED:
      return {
        ...state,
        isWinterSelected: action.isWinterSelected
      };

    case SITEPAGE_SET_IS_NON_WINTER_SELECTED:
      return {
        ...state,
        isNonWinterSelected: action.isNonWinterSelected
      };

    case SITEPAGE_SET_SELECTED_SITES:
      return {
        ...state,
        selectedSites: action.selectedSites
      };

    case SITEPAGE_SELECT_FILTERED_SITE:{
      const newSelectedSites = state.selectedSites.includes(action.id)
        ? state.selectedSites.filter(id => id !== action.id)
        : [...state.selectedSites, action.id];

      return {
        ...state,
        selectedSites: newSelectedSites
      };}

    case SITEPAGE_SELECT_ALL_FILTERED_SITES:
      return {
        ...state,
        selectedSites: action.selectedSites
      };

    case SITEPAGE_TOGGLE_SELECT_ALL_SITES:
      return {
        ...state,
        selectedSites: state?.selectedSites?.length === 0 ? [...action?.selectedSites] : []
      };

    case SITE_MANAGE_RESET:
      return initialState;

    // different company has different optimal page size, so reset here
    case "COMPANYPAGE_SELECT_COMPANY":
      return {
        ...state,
        pageSize: undefined
      };

    default:
      return state;
  }
};

export default sitesManage;

import React from 'react'
import { WwaCheckbox } from "../../common/components/WwaInputElements";
import 'common/css/inputElements.css'

const SiteReportCheckbox = ({u, siteId,
    handleReportCheckboxChange, handleReportSubtypeChange,
    reportSubs, isFetching, reportType}) => (
  <React.Fragment>
    <WwaCheckbox id={`site-report-user-${u.id}`}
                 name={reportSubs ? reportSubs.id : ''}
                 onChange={(e) => handleReportCheckboxChange(e, u, siteId, reportType)}
                 checked={!!reportSubs}
                 disabled={isFetching}
    />
    {reportType !== "snowtify"
      ?
        <select
          disabled={!reportSubs ? true : false}
          onChange={(e) => handleReportSubtypeChange(e,u,reportSubs)}
          className='wwa__select'
          value={reportSubs ? reportSubs.sendIfNecessary : true}
        >
          <option
            className='SiteSel__option'
            key={1}
            value={false}
          >Daily</option>
          <option
            className='SiteSel__option'
            key={2}
            value={true}
          >Events Only</option>
        </select>
      : null
    }
  </React.Fragment>
)

export default SiteReportCheckbox

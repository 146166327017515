import { connect } from 'react-redux'
import ReportsExecute from "./ReportsExecute";
import { executeReport, executeResendReport, setSelectedCompany } from './ReportsExecuteDuck'
import { loadCompanyPersons } from 'common/dataRetrieval/CompanyDuck';
import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import flatten from 'lodash/flatten';
import { reportTypes } from 'common/helpers/helpers';

const selectCompanies = state => state.companies;
const nameDS = companies => ({
  name: "name",
  keys: ["name"],
  data: Object.values(companies).sort(
    (a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1)
  )
});

const getCompanies = createSelector(
  selectCompanies,
  companies => nameDS(companies)
)

const selectCompanyPersons = (state) => state.reportsExecute.companyId ? state.companyPersons : {}
const selectCompanyId = (state) => state.reportsExecute.companyId || undefined
const selectSubscriptions = state => state.subscriptions;

const compUsersDS = (companyPersons, companyId) => ({
  name: 'users',
  keys: ['username', 'email'],
  data: Object.values(companyPersons[companyId]).filter(cp => cp.roles !== undefined)
})
const getUsers = createSelector(
  selectCompanyPersons,
  selectCompanyId,
  (companyPersons, companyId) => (!!companyId && companyPersons[companyId]) ? compUsersDS(companyPersons, companyId) : {}
)

const thirdPartyUsersDS = (companyPersons, companyId) => ({
  name: 'thirdpartyuser',
  keys: ['name', 'email'],
  data: Object.values(companyPersons[companyId]).filter(cp => cp.roles === undefined)
})
const getThirdPartyUsers = createSelector(
  selectCompanyPersons,
  selectCompanyId,
  (companyPersons, companyId) => (!!companyId && companyPersons[companyId]) ? thirdPartyUsersDS(companyPersons, companyId) : {}
)

const getSelectedCompanyPersonsById = createSelector(
  selectCompanyPersons,
  selectCompanyId,
  (companyPersons, companyId) => {
    if (companyPersons[companyId]) {
      const selectedCompanyPersonsById = {}
      companyPersons[companyId].forEach(cp => selectedCompanyPersonsById[cp.id] = cp)
      return selectedCompanyPersonsById
    }
    else
      return {}
  }
)

const getCompanyTypes = createSelector(
  selectSubscriptions,
  selectCompanyId,
  (subscriptions, companyId) => 
    reverse(sortBy((Object.values(subscriptions).find(s => s.companyId === companyId) || {}).types || []))
);

const getReportTypes = createSelector(
  getCompanyTypes,
  () => flatten(reportTypes)
);

const mapStateToProps = (state, ownProps) => {
  return {
    companyId: ownProps.companyId,
    showRestrictedForm: !!ownProps.companyId,
    selectedCompanyId: state.reportsExecute.companyId,
    companies: state.companies,
    companiesDS: getCompanies(state),
    companyPersons: state.companyPersons,
    reportTypes: getReportTypes(state),
    usersDS: getUsers(state),
    thirdPartyUsersDS: getThirdPartyUsers(state),
    selectedCompanyPersonsById: getSelectedCompanyPersonsById(state),
    isFetching: state.reportsExecute.isFetching,
    error: state.reportsExecute.error,
    success: state.reportsExecute.success,
  }
}

const mapDispatchToProps = (dispatch) => ({
  executeReport: (companyId, reportTypes, targetUserType, personIds, thirdPartyUserIds) =>
    dispatch(executeReport(companyId, reportTypes, targetUserType, personIds, thirdPartyUserIds)),
  executeResendReport: ( operationHour, overrideMailDrop ) =>
    dispatch(executeResendReport(operationHour, overrideMailDrop)),
  setSelectedCompany: companyId => dispatch(setSelectedCompany(companyId)),
  loadCompanyPersons: companyId => dispatch(loadCompanyPersons(companyId))
})

const ReportsExecuteContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsExecute);

export default ReportsExecuteContainer;
